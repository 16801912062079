* { 
  /* border: 2px solid red; */
}

body {
  color: #fff;
  font-family: "Raleway";
  font-size: 8pt;
  margin: 0px;
  overflow-x: hidden;
  text-align: center;
}

section {
  color: #616161;
  line-height: 11pt;
  padding: 40px 0 40px 0;
  position: relative;
}

a {
  color: inherit;
  text-decoration: none;
}

span{
  color: #04c2c9;
  font-weight: bold;
}

.button {
  border: 2px solid #fff;
  box-sizing: inherit;
  cursor: pointer;
  display: inline-block;
  font-size: 13pt;
  padding: 10px 20px;
  transition: all 0.5s;
}

.button:hover {
  background-color: #04c2c9;
  border-color: #04c2c9;
}
.button .mdi {
  vertical-align: middle;
}

.bold {
  font-family: "Raleway-bold";
}



.highlight {
  color: #e31b6d;
}

.label {
  font-size: 14pt;
  font-weight: 500;
}

.flex {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}