
.page-link { 
  cursor: pointer;
}

nav {
  bottom: 0;
  left: 0;
  position: absolute;
  background: #1b242f;
  border-bottom: 3px solid #04c2c9;
  height: 50px;
  text-align: left;
  width: 100%;
  z-index: 99;
}

nav .link-wrap {

  /* max-width: 1200px; */

  top: 53px;
  transition: height 0.3s ease-out;
  width: 100%;

  background: none;
  height: initial;
  overflow: visible;
  position: initial;
  text-align: left;
}

nav .link-wrap li {
  cursor: pointer;
  text-transform: uppercase;
  color: white;
  transition: color 0.5s;

  display: inline; 
  font-size: initial;
  margin: 0 20px;
  padding: initial;
}
nav .link-wrap li:first-child {
  padding: 0;
}
nav .link-wrap li:hover {
  color: #e31b6d;
}
nav .mdi-menu {
  font-size: 30pt;
  line-height: 36pt;
  margin: auto 0;
  position: absolute;
  right: 5vw;
  top: 0;
  display: none;
}
nav .active {
  color: #e31b6d;
}

#menu {
  padding: 0px 0 53px 0; 
}

