@keyframes left-center__resume-h1 {
    0%   {left:0px;}
    100% {left:44%;} 
  }

  @keyframes left-center__resume-portbar {
    0%   {left:0px;}
    100% {left:45%;} /**/
  }  

#resume {
    display: flex;
    flex-direction:  column;
    align-items:  center;
}

#resume h1 {
    text-align: left;
    position: absolute;
    left: 44%;
    font-size:3em;
    font-family: "Raleway-bold";
    margin: 0 0 20px 0;
    animation-name: left-center__resume-h1;
    animation-duration: 1s;    
}

#resume .header-bar {
    background: #444649;
    height: 4px;
    margin: 35px 0 0 0;
    width: 100px;
    position: absolute;
    left: 45%; 
    animation-name: left-center__resume-portbar;
    animation-duration: 1.5s;
}

#resume .resume-container {
    position: relative;
    overflow: hidden;
    width: 80%;
    height: 900px;
    margin-top: 60px;

}

.resume-container .iframe {
    width: 100%;
    height: 100%;
}