  @font-face {
    font-family: "Raleway";
    src: url("../fonts/Raleway-Regular.ttf");
  }
  @font-face {
    font-family: "Raleway-semibold";
    src: url("../fonts/Raleway-SemiBold.ttf");
  }
  @font-face {
    font-family: "Raleway-bold";
    src: url("../fonts/Raleway-Bold.ttf");
  }
  
  /* a {
    color: inherit;
    text-decoration: none;
  }
  
  body {
    color: #fff;
    font-family: "Raleway";
    font-size: 8pt;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0px;
    overflow-x: hidden;
    text-align: center;
  }
  
  #preload {
    display: none;
  } */
  
  .page-link {
    cursor: pointer;
  }
  
  .disable-hover {
    pointer-events: none;
  }
  
  .overflow-wrap {
    overflow-x: hidden;
  }
  
  .canvas {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100vw;
  }
  
  /* .button {
    border: 2px solid #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 13pt;
    transition: all 0.5s;

  }
  .button:hover {
    background-color: #04c2c9;
    border-color: #04c2c9;
  }

  .button .mdi {
    vertical-align: middle;
  } */
  
  /* .bold {
    font-family: "Raleway-bold";
  }

  .flex {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .flex.row {
    flex-direction: row;
  }
  .flex.wrap {
    flex-wrap: wrap;
  } */

  /* .highlight { 
    color: #e31b6d;
  } */

   #welcome {
    color: #fff;
    height: 100vh;
    /* height: 53px; */
    padding: 0 10px;
  }
   #welcome .highlight {
    font-family: "Raleway-semibold";
  }
   #welcome .text {
    font-size: 16pt;
    line-height: 20pt;
    margin: 0 0 20px 0;
    pointer-events: none;
    z-index: 1;
  }
   #welcome .hexagon {
    transition: transform 0.9s ease-in-out;
  }
   #welcome .hexagon:hover {
    transform: rotate(540deg);

  }

   #welcome .button {
    margin-bottom: 53px;
    padding: 12px 30px 12px 15px;
    position: relative;

  }

   #welcome .button .mdi {
    position: relative;
    bottom: 10px;
    left: 0px;
    top: 0px;
    transition: scale 1.7s;
    transition: left 0.5s;
  }

   #welcome .button .view {
    transition: font-size 0.5s;

  }
  
   #welcome .button:hover .mdi {
    left: 25px;
    transform: scale(1.5);

  }

   #welcome .button:hover .view {
    font-size: 24px;

  } 

   @media screen and (min-width: 600px) {
     #welcome .text {
      font-size: 32pt;
      line-height: 36pt;
    }
     #welcome .button { 
      padding: 12px 35px 12px 20px;
    }
     #welcome .button .mdi {
      top: 0px;
    }
  } 

  @media screen and (min-width: 960px) {
    /* .button {
      font-size: 16pt;
    } */
    .canvas {
      background: #252934;
    }
  }