@keyframes left-center__bar {
  0%   {left:0px;}
  100% {left:44%;} /**/
}

@keyframes left-center__h1 {
  0%   {left:0px;}
  100% {left:44%;} /**/
}

@keyframes opasidad {
  0%   {opacity: 0;}
  100% {opacity:1;}
}

@keyframes img-animation {
  0%   {opacity: 0; bottom: -60px;}
  100% {opacity:1; bottom: 0;}
}

@keyframes info-animation {
  0%   {opacity: 0; right: -60px;}
  100% {opacity:1; right: 0;}
}

@keyframes link-animation {
  0%   {font-size: 2rem;}
  100% {font-size: 2.5rem;}
}

/*--------------------0---------------------*/
#about {
  /* width: 900px;
  position: absolute;
  left: 50%;
  margin-left: -450px;
  font-size: larger; */
}
/*------------------------------------------*/
/*--------------------1---------------------*/
#about h1 {
  text-align: left;
  position: relative;
  left: 44%;
  font-size:3em;
  font-family: "Raleway-bold";
  margin: 0 0 20px 0;
  animation-name: left-center__h1;
  animation-duration: 1s;

}

#about .header-bar {
  background: #444649;
  height: 4px;
  margin: 0 0 35px 0;
  width: 100px;
  position: relative;
  left: 44%; 
  animation-name: left-center__bar;
  animation-duration: 1.5s;
}
/*-----------------------------------------*/

/*--------------------2--------------------*/
.skilles {
  display: flex;
  justify-content: center;
}

.logos {
width: 600px;
height: 150px;
display: flex;
justify-content: space-evenly;
animation-name: opasidad;
animation-duration: 1.5s;
}
.skill {
  width: 85px;
  text-align: center;
}
.hex-contenedor {
  background-color: #04c2c9;
  height: 72px;
  padding:0px 0px 0px 0px ;
  border-radius: 5px;
}

.logo {
  color: white;
  font-size: 6em;
}

.logo-desc {
  width: 100%;
  margin: -10px 0px 0px 0px;
  line-height: 0.9;
}

.logo-desc h2 {
  font-size:1.5em;
}
/*-----------------------------------------*/
/*--------------------3--------------------*/
.about-info {
  display: flex;
  justify-content: center;
  /* max-width: 900px; */
}

.img-info__container {
  max-width: 900px;
  display: flex;
  justify-content: center;
}
.img-info__container img {
  position: relative;
  border-radius: 50%;
  width: 230px;
  height: 221.3px;
  animation-name: img-animation;
  animation-duration: 1.5s;
}
.info {
   position: relative;
  /*display: block; */
  margin: 0px 0px 0px 0px;
  padding: 0 20px 0 20px;
  animation-name: info-animation;
  animation-duration: 1.5s;
  /* background-color: slateblue; */
}

.info h2 {
  font-family: "Raleway-bold";
  font-size:2em;
}

.info p {
  font-size:1.25em;
  text-align: justify;
}

.info .links {
  max-width: 900px;
  display: flex;
  justify-content: space-evenly;
  margin-top: 30px;
  
}

.info .links .linkedin, .github, .instagram {
  font-size: 2rem;
  color: #444649;
}

.info .links .linkedin:hover, .github:hover, .instagram:hover {
  animation-name: link-animation;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  color: #e31b6d;
}


/*--------------------end--------------------*/

/* @media screen and (max-width: 750px) {

  .about-info {
    display: flex;
    justify-content: center;
    width: 600px;
  }

  .info .links {
     width: 335px;
    position: absolute;
    left: 50%; 
    position:inherit;
    margin-left: 0px;
     display: flex;
    justify-content: space-evenly; 
    
  }

}  */

@media screen and (max-width: 600px) {
  .logos {
    width: 400px;
    height: 230px;
    display:block;
    animation-name: opasidad;
    animation-duration: 1.5s;
    }

    .img-info__container {
      display:block;
      justify-content: left;
      max-width: 230px;
      margin: -240px 0 0 200px;
    }

    .img-info__container img {
      /* position: relative; */
      border-radius: 50%;
      width: 200px;
      height: 200px;
      animation-name: img-animation;
      animation-duration: 1.5s;
    }

} 

