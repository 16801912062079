@keyframes left-center__portbar {
  0%   {left:0px;}
  100% {left:42.5%;} /**/
}

@keyframes left-center__tittle {
  0%   {left:0px;}
  100% {left:40%;} /**/
}

  #portfolio {
    /* background: #f5f5f5; */
    width: 840px;
    position: absolute;
    left: 50%;
    margin-left: -420px;

  }

  #portfolio .tittle {
    text-align: left;
    position: relative;
    left: 40%;
    font-size:3em;
    font-family: "Raleway-bold";
    margin: 0 0 20px 0;
    animation-name: left-center__tittle;
    animation-duration: 1s;
  }

  #portfolio .header-bar {
    background: #444649;
    height: 4px;
    margin: 0 0 35px 0;
    width: 100px;
    position: relative;
    left: 42.5%; 
    animation-name: left-center__portbar;
    animation-duration: 1.5s;
  }

  #portfolio .filter-wrap {
    position: relative;
    white-space: nowrap;
  }

  #portfolio .filter-wrap .row .button {
    background-color: transparent;
    border: none;
    font-family: "Raleway";
    font-size: 18px;
    color: #616161;
    height: 25px;
  }
  
  #portfolio .filter-wrap .row .button:hover {
    background: #e31b6d;
    color: #fff;
  }

  #portfolio .filter-wrap .row .active-button {
    border: none;
    font-family: "Raleway";
    font-size: 18px;
    height: 45px;
    background: #e31b6d;
    color: #fff;
  }

  #portfolio .text {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  #portfolio .gallery-container{
    display: flex;
    justify-content: center;
  }

  #portfolio #gallery {

    display: grid;
    grid-template-columns: repeat(3, 280px);
  }
  
  #portfolio #gallery .text,
  #portfolio #gallery .button {
    color: #1b242f;
    opacity: 0;
    text-align: center;
    transition: all 0.3s ease-in-out;
    z-index: 2;
  }

  #portfolio #gallery .button {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    border: 2px solid #e31b6d;
    font-size: 13pt;
    margin: 0 auto;
    padding: 7px 0;
    width: 170px;
  }

  #portfolio #gallery .text .bold,
  #portfolio #gallery .button .bold {
    font-size: 16pt;
  }

  #portfolio #gallery .text .bold {
    height: 25px;
    padding-top: 5px;
  }
  
  #portfolio #gallery .mix {
    position: relative;
  }

  #portfolio #gallery .mix .button:hover {
    border-color: #fff;;
  }

  #portfolio #gallery .mix .card {
    height: 240px;
    max-width: 100%;
    transition: opacity 0.5s;
    width: calc(50vw - 8px);
  }

  #portfolio #gallery .mix:hover .card {
    opacity: 0.5;
  }

  #portfolio #gallery .mix .text span {
    font-size: 1.5em;

  }

  #portfolio #gallery .mix:hover .text {
    cursor: default;
    opacity: 1;
    top: 24%;
    background-color: #fff;
    height: 50px;
  }

  #portfolio #gallery .mix:hover .button {
    bottom: 24%;
    opacity: 1;
    background: #e31b6d;
    color: #fff;
  }

  @media screen and (max-width: 770px) {

    #portfolio #gallery {

      display: grid;
      grid-template-columns: repeat(2, 250px);
    }
    
    #portfolio .header-bar {

      left: 43%;  
    }
  
  } 

  @media screen and (max-width: 550px) {

    #portfolio #gallery {

      display: grid;
      grid-template-columns: repeat(1, 250px);
    }
  
  }






