@media screen and (min-width: 960px) {
    .way {
        opacity: 0;
      }

    .slide-in-left {
        animation: slideInLeft 0.75s ease both;

    }
    .slide-in-right {
        animation: slideInRight 0.75s ease both;
      }

    .flip-in-x {
        animation: flipInX 0.75s ease both;
      }

    .fade-in {
        animation: fadeIn 1s ease both;
      }

    .dly5 {
        animation-delay: .5s;
    }

    .dly3 {
      animation-delay: .3s;
    }    

    .hex1 {
        animation-delay: .2s;
    }

    .hex2 {
        animation-delay: .4s;
    }

    .hex3 {
        animation-delay: .6s;
    }                 

    @keyframes fadeIn {
    100% {
        opacity: 1;
    }
    }

    @keyframes slideInLeft {
        0% {
        opacity: 0;
        transform: translateX(-300px);
        }
        100% {
        opacity: 1;
        transform: translateX(0);
        
        }
    }

    @keyframes slideInRight {
        0% {
          opacity: 0;
          transform: translateX(300px);
        }
        100% {
          opacity: 1;
          transform: translateX(0);
        }
      }

    @keyframes flipInX {
        from {
          -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
          opacity: 0;
          -webkit-transform: perspective(400px) rotateY(90deg);
          transform: perspective(400px) rotateY(90deg);
        }
        40% {
          -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
          -webkit-transform: perspective(400px) rotateY(-20deg);
          transform: perspective(400px) rotateY(-20deg);
        }
        60% {
          opacity: 1;
          -webkit-transform: perspective(400px) rotateY(10deg);
          transform: perspective(400px) rotateY(10deg);
        }
        80% {
          -webkit-transform: perspective(400px) rotateY(-5deg);
          transform: perspective(400px) rotateY(5deg);
        }
        to {
          opacity: 1;
          -webkit-transform: perspective(400px);
          transform: perspective(400px);
        }
      }
}